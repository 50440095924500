import { ErrorPage, PopUpError } from "@Components/ErrorTemplate";

import CalendarError from "./Calendar";
import InvalidDateError from "./InvalidDate";

export enum IncorrectDetailsCodes {
  PaymentDetailsIncorrect = "payment_details_incorrect",
  IncorrectCVC = "incorrect_cvc",
  IncorrectZIP = "incorrect_zip",
}

export enum DeclineCodes {
  GenericDecline = "generic_decline",
  StolenCard = "stolen_card",
  LostCard = "lost_card",
  ProcessingError = "processing_error",
  ExpiredCard = "expired_card",
  AuthenticationFailure = "payment_intent_authentication_failure",
}

export const errors = {
  alreadyChecked: "already-checked",
  applePay: {
    incomplete: "incomplete",
  },
  booking: {
    invalidDate: "booking_invalid_error",
  },
  calendar: "calendar",
  card: {
    declineCodes: [
      DeclineCodes.AuthenticationFailure,
      DeclineCodes.ExpiredCard,
      DeclineCodes.GenericDecline,
      DeclineCodes.ProcessingError,
      DeclineCodes.StolenCard,
      DeclineCodes.LostCard,
    ],
    incorrectDetailsCodes: [
      IncorrectDetailsCodes.PaymentDetailsIncorrect,
      IncorrectDetailsCodes.IncorrectCVC,
      IncorrectDetailsCodes.IncorrectZIP,
    ],
    insufficient_funds: "insufficient_funds",
    three_d_s_bypassed: "three_d_s_bypassed",
    three_d_s_error: "three_d_s_error",
    three_d_s_failed: "three_d_s_failed",
  },
  financeDeclined: "finance-declined",
  financeFailed: "finance-failed",
  general: "general",
  invalidDateSelected: "invalid_date_selected",
  invalidInstallationPrice: "invalid_installation_price",
  invalidPromo: "invalid-promo",
  quoteExpired: "quote-expired",
  rentalGeneric: "rental-generic",
  wentWrong: "went-wrong",
  wentWrongStartAgain: "went-wrong-start-again",
};
export type Props = {
  errorKey: string | null;
  onDismiss?: () => void;
  onStartAgain?: () => void;
};

const ErrorHandler = ({ errorKey, onDismiss, onStartAgain }: Props) => {
  let key = errorKey;
  // Stipe has different codes for incorrectDetails
  if (errors.card.incorrectDetailsCodes.includes(errorKey as IncorrectDetailsCodes)) {
    key = IncorrectDetailsCodes.PaymentDetailsIncorrect;
  }
  if (errors.card.declineCodes.includes(errorKey as DeclineCodes)) {
    key = DeclineCodes.GenericDecline;
  }
  switch (key) {
    case IncorrectDetailsCodes.PaymentDetailsIncorrect:
    case errors.card.insufficient_funds:
    case DeclineCodes.GenericDecline:
    case errors.card.three_d_s_failed:
    case errors.card.three_d_s_error:
    case errors.card.three_d_s_bypassed:
      return (
        <PopUpError
          ariaLabel={`error-${key}`}
          buttonType="dismiss"
          contentTemplate="double"
          nameSpace={{ key, path: "checkout" }}
          onDismiss={onDismiss}
        />
      );
    case errors.financeFailed:
    case errors.financeDeclined:
    case errors.invalidInstallationPrice:
      return (
        <PopUpError
          ariaLabel={`error-${key}`}
          buttonType="dismiss"
          contentTemplate="double"
          nameSpace={{ key, path: "checkout" }}
          onDismiss={onDismiss}
        />
      );
    case errors.invalidDateSelected:
      return (
        <PopUpError
          ariaLabel={`error-${key}`}
          buttonType="dismiss"
          contentTemplate="single"
          nameSpace={{ key, path: "checkout" }}
          onDismiss={onDismiss}
        />
      );
    case errors.invalidPromo:
      return (
        <PopUpError
          ariaLabel={`error-${key}`}
          buttonType="dismiss"
          contentTemplate="double"
          nameSpace={{ key, path: "common" }}
          onDismiss={onDismiss}
        />
      );
    case errors.alreadyChecked:
      return (
        <PopUpError
          ariaLabel={`error-${key}`}
          buttonType="home"
          contentTemplate="single"
          nameSpace={{ key, path: "checkout" }}
          onDismiss={onDismiss}
        />
      );
    case errors.wentWrong:
    case errors.wentWrongStartAgain:
      return <ErrorPage error={key} onStartAgain={onStartAgain} />;
    case errors.calendar:
      return <CalendarError />;
    case errors.booking.invalidDate:
      return <InvalidDateError />;
    // incomplete means that been canceled by user, or biometric is failed
    // in this case we should not fire an error popup
    case errors.applePay.incomplete:
      return null;
    case errors.rentalGeneric:
      return (
        <PopUpError
          ariaLabel={`error-${key}`}
          buttonTheme="coral"
          buttonType="dismiss"
          contentTemplate="single"
          nameSpace={{ key, path: "boilers/subscription/checkout" }}
          onDismiss={onDismiss}
        />
      );
    case errors.quoteExpired:
      return (
        <PopUpError
          ariaLabel={`error-${errors.quoteExpired}`}
          buttonType="dismiss"
          contentTemplate="single"
          nameSpace={{ key: errors.quoteExpired, path: "common" }}
          onDismiss={onDismiss}
        />
      );
    default:
      return (
        <PopUpError
          ariaLabel={`error-${errors.general}`}
          buttonType="reload"
          contentTemplate="single"
          nameSpace={{ key: errors.general, path: "common" }}
          onDismiss={onDismiss}
        />
      );
  }
};
export default ErrorHandler;
