import type { RefObject } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import { useFsFlag } from "@flagship.io/react-sdk";
import { ReactLenis } from "@studio-freight/react-lenis";
import type { NavigationProps } from "@boxt/design-system";

import { getButterComponentsForPage, getHeroForPage } from "@Helpers/renderButterComponents";
import { sendToCs } from "@Lib/contentsquare";
import ErrorHandler from "@Components/ErrorHandler";
import { heroProductWidgetContent } from "@Components/HeroProductWidget/content";
import { HeroProductWidget } from "@Components/HeroProductWidget/HeroProductWidget";
import ButterPropositionBanner from "@Components/pages/common/ButterPropositionBanner";
import type { ButterPropositionBannerFields } from "@Components/pages/common/ButterPropositionBanner/types";
import Loader from "@Components/pages/common/Loader";
import ContentPageComponent from "@Components/pages/content/components/ContentPage";
import { StyledPage } from "@Components/pages/content/styles";
import type { ButterContentPageFields } from "@Components/pages/content/types";
import PromoCodeToast from "@Components/PromoCodeToast/PromoCodeToast";
import useChat from "@Hooks/useChat";
import useCleanCookie from "@Hooks/useCleanCookie";
import usePromoCode from "@Hooks/usePromoCode";
import { useSetFilterCookie } from "@Hooks/useSetFilterCookie/useSetFilterCookie";

const Navigation = dynamic<NavigationProps>(() => import("@boxt/design-system").then((library) => library.Navigation), {
  ssr: false,
});

export type Props = {
  fields?: ButterContentPageFields;
  slug?: string;
  destination: string;
  hasFlagshipLoaded: boolean;
  redirectOnClient?: boolean;
};
const PreviewPage = ({ fields, slug, destination, hasFlagshipLoaded, redirectOnClient }: Props) => {
  const { push, isFallback, query, isReady } = useRouter();
  const { openChat, hide, minimize } = useChat();

  const isHeatPumpFeatureFlagActive = useFsFlag("heat-pumps-in-main-nav", false).getValue();

  const isFeatureFlagActive = useFsFlag(fields?.flagship_flag as string, false).getValue();
  const isPolicyIntegrationFlagActive = useFsFlag("policy-integration", false).getValue();
  const isBlpRedirectEnabled = useFsFlag("blp_full_page_reformat", false).getValue();
  const productWidgetEnabled = useFsFlag("homepage-product-widget", false).getValue();
  const isProductWidgetEnabled = productWidgetEnabled && slug === "home-page";
  const scrollTargetRef: RefObject<HTMLDivElement> | null = useRef(null);

  const { hasCleanParam, cleanAll, removeCleanDataQueryParam } = useCleanCookie();
  const { hasActivePromoCode, promoCodeData, promoCodeError, removePromoCodeQueryParam } = usePromoCode();

  useSetFilterCookie();

  useEffect(() => {
    if (slug === "cover") {
      sendToCs("AB_ABT_FS_policy-integration", "New variation");
    }
  }, [slug]);

  useEffect(() => {
    if (slug === "boilers-ab" && isBlpRedirectEnabled) {
      sendToCs("AB_ABT_FS_blp_full_page_reformat", "New variation");
    } else if (slug === "boiler" && isBlpRedirectEnabled) {
      sendToCs("AB_ABT_FS_blp_full_page_reformat", "Original");
    }
  }, [isBlpRedirectEnabled, slug]);

  useEffect(() => {
    if (slug === "home-page") {
      sendToCs("AB_ABT_FS_homepage_product_widget", productWidgetEnabled ? "New variation" : "Original");
    }
  }, [productWidgetEnabled, slug]);

  useEffect(() => {
    const cleanData = async () => {
      await cleanAll();
    };
    if (hasCleanParam) {
      cleanData();
      removeCleanDataQueryParam();
    }
  }, [cleanAll, hasCleanParam, removeCleanDataQueryParam, slug]);

  const shouldRedirect =
    redirectOnClient ||
    (hasFlagshipLoaded &&
      (fields?.flagship_flag ? !isFeatureFlagActive && isReady && query?.[fields.flagship_flag] !== "true" : false));

  const [isSmoothScrollingEnabled, setIsSmoothScrollingEnabled] = useState(fields?.smooth_scrolling);

  useEffect(() => {
    if (shouldRedirect) {
      push(destination);
    }
  }, [shouldRedirect, destination, push]);

  const renderedHero = getHeroForPage(fields?.modules, slug, scrollTargetRef);

  const renderedModules = getButterComponentsForPage(fields?.modules, slug, scrollTargetRef);

  const toggleSmoothScrolling = useCallback(
    (isOpen) => {
      if (isOpen) {
        setIsSmoothScrollingEnabled(false);
      } else {
        if (fields?.smooth_scrolling) {
          setIsSmoothScrollingEnabled(true);
        }
      }
    },
    [fields?.smooth_scrolling],
  );

  const isLoading = isFallback || shouldRedirect;

  const pageContent = (
    <>
      {isLoading ? <Loader /> : <ContentPageComponent renderedHero={renderedHero} renderedModules={renderedModules} />}
    </>
  );

  const propositionBanner = fields?.modules?.find(({ type }) => {
    return type === "proposition_banner";
  });

  const boilersUrl = `${getConfig().publicRuntimeConfig.BOXT_CUSTOMER_SITE_URL}/boilers`;
  const hasCanonicalLink = slug === "boilers-ab";

  return (
    <StyledPage>
      <Head>
        <title>{fields?.page_title}</title>
        <meta content={fields?.page_description} name="description" />
        {fields?.no_index && <meta content="noindex, follow" name="robots" />}
        {hasCanonicalLink && <link href={boilersUrl} rel="canonical" />}
      </Head>
      <>
        <Navigation
          hideLiveChat={hide}
          isHeatPumpFeatureFlagActive={isHeatPumpFeatureFlagActive}
          isPolicyIntegrationFlagActive={isPolicyIntegrationFlagActive}
          isWWAFeatureFlagActive
          minimizeLiveChat={minimize}
          onOpenToggle={toggleSmoothScrolling}
          openLiveChat={openChat}
        >
          {propositionBanner && (
            <ButterPropositionBanner fields={propositionBanner.fields as ButterPropositionBannerFields} />
          )}
        </Navigation>
        {isSmoothScrollingEnabled ? (
          <ReactLenis options={{ duration: 1.3 }} root>
            {pageContent}
          </ReactLenis>
        ) : (
          pageContent
        )}
        {isProductWidgetEnabled && (
          <HeroProductWidget
            content={heroProductWidgetContent}
            i18nNamespace="common"
            testId="homepage-hero-product-widget"
          />
        )}
        {hasActivePromoCode ? <PromoCodeToast promoCode={promoCodeData} /> : null}
        {Boolean(promoCodeError) && <ErrorHandler errorKey={promoCodeError} onDismiss={removePromoCodeQueryParam} />}
      </>
    </StyledPage>
  );
};

export default PreviewPage;
